import { CButton, CCol, CListGroup, CRow } from '@coreui/react-pro';
import { AppRoute, PersonalRoute } from 'common/enums/enums';
import { CarServiceDto, CarServiceEditRequestDto } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { generateDynamicPath } from 'helpers/helpers';
import { useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactCard, DetailsCard, InfoCard } from '../common/common';
import { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD } from './common';

type Props = {
  carService: CarServiceDto | null;
};

const CarServiceDetails: FC<Props> = ({ carService }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('carServiceDetails');
  const { onPermissionAction } = usePermissionToastContext();

  const {
    register,
    watch,
  } = useForm<CarServiceEditRequestDto>({
    defaultValues: DEFAULT_EDIT_CAR_SERVICE_PAYLOAD,
  });

  const watchInfo = watch('info');

  const linkToEditCarService = (): void => {
    navigate(`${AppRoute.PERSONAL}${PersonalRoute.ROOT}${
      generateDynamicPath(PersonalRoute.EDIT_CAR_SERVICE_$CAR_SERVICE_ID,
        { carServiceId: carService?.id ?? '' })}`);
  };

  return (
    <CListGroup flush>
      <CRow>
        <CCol xl={6} md={12}>
          <InfoCard register={register} value={watchInfo}/>
        </CCol>
        <CCol xl={6} md={12}>
          <ContactCard />
          <DetailsCard />
        </CCol>
      </CRow>
      <CCol className="align-self-end pt-3">
        <CButton variant="outline" onClick={ onPermissionAction(linkToEditCarService) }>
          {t('carServiceDetailsEditCaptionButton')}
        </CButton>
      </CCol>
    </CListGroup>
  );
};

export { CarServiceDetails };
