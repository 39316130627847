import { AppTranslationKey, LocalStorageKey, ModalFormMode, ModalFormType, Pages } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { CarModalForm } from 'components/modals/modals';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useAppDispatch, useAppSelector, useEffect, useState } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';
import { carActions } from 'store/actions';
import { clearModalSelectedCar } from 'store/car/reducer';
import { disableReadOnlyModalMode, enabledReadOnlyModalMode } from 'store/modal/reducer';

import { CarTable } from './components/components';

const CarServicesAuto: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const [ isCarModalOpen, setIsCarModalOpen ] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { onPermissionAction } = usePermissionToastContext();
  const { i18n } = useTranslation();

  const {
    carModalFormMode,
    modalSelectedCar,
  } = useAppSelector(({ car, modal }) => ({
    modalSelectedCar: car.modalSelectedCar,
    carModalFormMode: modal.modalFormMode?.[ModalFormType.CAR_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));

  const isCarModalFormReadOnly = carModalFormMode === ModalFormMode.READONLY_MODE;

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_SERVICE_AUTO}:addCarCaptionButton`,
      action: onPermissionAction(handleOpenAddCarModal, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
      storage.removeItem(LocalStorageKey.PERSIST_PAGINATION_STATE);
    };
  }, [i18n.language]);

  const handleViewEditCarClick = (carId: number, readOnly: boolean): void => {
    dispatch(readOnly ?
      enabledReadOnlyModalMode(ModalFormType.CAR_MODAL) :
      disableReadOnlyModalMode(ModalFormType.CAR_MODAL));
    dispatch(carActions.getCarById({ carId }));
    handleOpenCarModal();
  };

  const handleOpenAddCarModal = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.CAR_MODAL));
    handleOpenCarModal();
  };
  const handleOpenCarModal = (): void => {
    setIsCarModalOpen(true);
  };

  const handleCloseCarModal = (): void => {
    dispatch(clearModalSelectedCar());
    setIsCarModalOpen(false);
  };

  return (
    <>
      <CarTable
        activeCarService={activeCarService?.id ?? 0}
        handleViewEditCarClick={handleViewEditCarClick}
        handleOpenAddCarModal={handleOpenAddCarModal}
      />
      <CarModalForm
        car={modalSelectedCar}
        isReadOnly={isCarModalFormReadOnly}
        isOpen={isCarModalOpen}
        onCloseCarModal={handleCloseCarModal}
        carServiceId={activeCarService?.id ?? 0}
      />
    </>
  );
};

export const AutoWithCarServicesTabs =
  withCarServicesTabs(
    CarServicesAuto,
    false,
    Pages.CARS,
    () => t('mainMenu:carsNavItem') ?? '',
  );
