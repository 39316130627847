import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import React, { FC } from 'react';

import styles from './styles.module.scss';

const DetailsCard: FC = () => {

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>Details</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <CFormInput type="text" label="Name of the recipient" name="Name of the recipient" />
          </div>
          <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '25%' }}>
              <CFormInput type="text" label="IPN" name="IPN" />
            </div>
            <div style={{ width: '73%' }}>
              <CFormInput type="text" label="Address" name="address" />
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '25%' }}>
              <CFormInput type="text" label="EDRPOU" name="EDRPOU" />
            </div>
            <div style={{ width: '73%' }}>
              <CFormInput type="text" label="Current account (р/р, IBAN)" name="Current account (р/р, IBAN)" />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '25%' }}>
            <CFormInput type="text" label="MFO" name="MFO" />
          </div>
          <div style={{ width: '73%' }}>
            <CFormInput type="text" label="Bank" name="bank" />
          </div>
        </div>
      </CCardBody>
    </div>
  )
  ;
};

export { DetailsCard };
