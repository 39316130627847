import {
  AppRoute, AppTranslationKey, DataStatus, Pages, PersonalRoute, StorageTranslationKey } from 'common/enums/enums';
import { WithStorageTabs } from 'common/types/types';
import { NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { SparePartModalForm } from 'components/modals/modals';
import {
  SparePartModalContextProvider,
  useSparePartModalContext,
} from 'components/modals/spare-part-modal/context/spare-part-modal-context';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withStoragesTabs } from 'hoc/hoc';
import { useAppSelector, useEffect, useNavigate } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SparePartsTableContextProvider } from './spare-parts-table/context/spare-parts-table-context';
import { SparePartsTable } from './spare-parts-table/spare-parts-table';

const SparePartsInner: FC<WithStorageTabs> = ({ activeStorage })  => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation(AppTranslationKey.SPARE_PARTS);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { setSetting } = useSparePartModalContext();
  const { onPermissionAction } = usePermissionToastContext();

  const { stocks, getStockDataStatus } = useAppSelector(({ stock }) => ({
    stocks: stock.stocks,
    getStockDataStatus: stock.getStocksByUserIdDataStatus,
  }));

  const isLoadingStock = getStockDataStatus === DataStatus.PENDING;

  const handledAddStorage = (): void => {
    navigate(`${AppRoute.PERSONAL}/${PersonalRoute.ADD_STORAGE}`);
  };

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.SPARE_PARTS}:addButtonTitle`,
      action: onPermissionAction((): void => {
        setSetting({ isReadOnly: false, sparePart: null, stockId: activeStorage?.stockId });
        // onOpenSparePartModal();
      }, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [activeStorage, i18n.language]);

  if (isLoadingStock) {
    return <Spinner isOverflow containerHeight={250}/>;
  }

  if (stocks.length === 0) {
    return <NoDataWithAction
      title={t(StorageTranslationKey.NO_DATA_TITLE, { ns: AppTranslationKey.STOCK })}
      message={t(StorageTranslationKey.NO_DATA_TEXT, { ns: AppTranslationKey.STOCK })}
      actionName={t(StorageTranslationKey.ADD_STORAGE, { ns: AppTranslationKey.STOCK })}
      callback={onPermissionAction(handledAddStorage, Tariffs.NO_TARIFF)}
    />;
  }

  return (activeStorage && <SparePartsTable stockId={activeStorage?.stockId}/>);
};

const SpareParts: FC = () => (
  <SparePartsTableContextProvider>
    <SparePartModalContextProvider>
      {withStoragesTabs(
        SparePartsInner,
        Pages.STORAGE,
        () => t('mainMenu:parts') ?? '',
      )()}
      <SparePartModalForm />
    </SparePartModalContextProvider>
  </SparePartsTableContextProvider>
);

export { SpareParts };
