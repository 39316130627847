import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';

import { getCertificateOfCompletedWork, getDocById } from './action';

type State = {
  certificateOfCompletedWorkUrl: string,
  docUrl: string,
  dataStatusCertificateOfCompletedWork: DataStatus,
  dataStatusGetDocById: DataStatus,
};

const initialState: State = {
  certificateOfCompletedWorkUrl: '',
  docUrl: '',
  dataStatusCertificateOfCompletedWork: DataStatus.IDLE,
  dataStatusGetDocById: DataStatus.IDLE,
};

const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // get Certificate Of Completed Work
      .addCase(getCertificateOfCompletedWork.pending, (state) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.PENDING;
      })
      .addCase(getCertificateOfCompletedWork.rejected, (state) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.REJECTED;
        state.certificateOfCompletedWorkUrl = initialState.certificateOfCompletedWorkUrl;
      })
      .addCase(getCertificateOfCompletedWork.fulfilled, (state, { payload }) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.FULFILLED;
        state.certificateOfCompletedWorkUrl = payload.url;
      })
      //get document
      .addCase(getDocById.pending, ( state ) => {
        state.dataStatusGetDocById = DataStatus.PENDING;
      })
      .addCase(getDocById.rejected, ( state ) => {
        state.dataStatusGetDocById = DataStatus.REJECTED;
        state.docUrl = '';
      })
      .addCase(getDocById.fulfilled, (state, { payload }) => {
        state.dataStatusGetDocById = DataStatus.FULFILLED;
        state.docUrl = payload.url;
      });
  },
});

const documentationReducer = documentationSlice.reducer;
export { documentationReducer };
