import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  errors: FieldErrors<EmployeeFormData>,
};

const SurnameField: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const componentProps = register(
    'secondName',
    { required: t(EmployeesProfilesKey.REQUIRED_FORM_FIELD) ?? 'This field is required' },
  );

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="surname" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.SECOND_NAME }`) }*` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <CFormInput
          { ...componentProps }
          id="surname"
          invalid={Boolean(errors.secondName)}
          feedbackInvalid={errors.secondName?.message || ''}
        />
      </CCol>
    </CRow>
  );
};

export { SurnameField };
