import { CButton, CCard, CCardBody, CListGroup, CListGroupItem, CSmartTable } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import {
  AppTranslationKey,
  DataStatus,
  ModalFormMode,
  ModalFormType,
} from 'common/enums/enums';
import { CarDto, ClientSpareParts, SparePartsInOrder, WorkDto } from 'common/types/types';
import {
  CarStateNumber,
  OrderCertificateButton,
  TableField,
  usePermissionToastContext,
} from 'components/common/common';
import { Spinner } from 'components/common/spinner/spinner';
import { useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getOrderClientSparePartsTableColumns,
  getOrderSparePartsTableColumns,
  getWorkFieldTableColumns,
} from '../helpers';
import styles from './style.module.scss';

type Props = {
  comment: string,
  auto: CarDto,
  orderTask: WorkDto[],
  spares: SparePartsInOrder[],
  clientSpares: ClientSpareParts[],
  onViewEditOrderClick: (readOnly: boolean) => void,
  id: number,
};

const OrderDetails: FC<Props> = (
  {
    comment,
    auto,
    orderTask,
    spares,
    onViewEditOrderClick,
    clientSpares,
    id,
  }) => {
  const { t } =
    useTranslation([AppTranslationKey.CAR_SERVICE_ORDER, AppTranslationKey.CAR_SERVICE_AUTO]);
  const {
    dataStatusOrder,
    modalFormMode,
  } = useAppSelector(({ task, modal }) => ({
    dataStatusOrder: task.dataStatusOrder,
    modalFormMode: modal.modalFormMode?.[ModalFormType.ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));
  const { onPermissionAction } = usePermissionToastContext();

  const isLoading = dataStatusOrder === DataStatus.PENDING;
  const isOrderModalFormReadOnly = modalFormMode === ModalFormMode.READONLY_MODE;
  const orderTaskColumns = getWorkFieldTableColumns();
  const orderSparePartsColumns = getOrderSparePartsTableColumns();
  const orderClientSparePartsColumns = getOrderClientSparePartsTableColumns();

  return (
    <CCard color="light">
      <CCardBody>
        <CListGroup>
          <CListGroupItem>
            <div style={{ display: 'flex' }}>
              <p>{t('orderAuto')}: </p>
              <h5 className="ms-3">
                { `${ auto?.carBrandName ?? '' } ${ auto?.carModelName ?? '' }` }
              </h5>
            </div>
            <div className={styles.carTitle}>
              <div className="me-3">
                <CarStateNumber carStateNum={auto?.carRegNum} />
              </div>
              <div>
                <div>
                  {t('createYear', { ns: AppTranslationKey.CAR_SERVICE_AUTO })}:  {auto?.createYear ?? '----'}
                </div>
                <p>{t('vinCode', { ns: AppTranslationKey.CAR_SERVICE_AUTO })}:  {auto?.vinCode ?? '-----'}</p>
              </div>
            </div>
          </CListGroupItem>
          <CListGroupItem>
            {orderTask.length > 0 && <p>{t('orderProblemTitle')}</p>}
            <TableField
              isDisable={true}
              onRemoveClick={(): void => {
                //do nothing
              }}
              columns={ orderTaskColumns }
              data={ orderTask }
              noDataMsg={t('orderNoWork') ?? 'The order has not works'}
            />
          </CListGroupItem>
          <CListGroupItem>
            {spares.length > 0 && <p>{t('orderSparePartsTitle')}</p>}
            <TableField
              isDisable={true}
              onRemoveClick={(): void => {
                //do nothing
              }}
              columns={ orderSparePartsColumns }
              data={ spares }
              noDataMsg={t('orderNoSpareParts') ?? 'The order has not spare parts'}
            />
          </CListGroupItem>
          <CListGroupItem>
            {clientSpares.length > 0 && <p>{t('orderClientSparePartsTitle')}</p>}
            <TableField
              isDisable={true}
              onRemoveClick={(): void => {
                //do nothing
              }}
              columns={ orderClientSparePartsColumns }
              data={ clientSpares }
              noDataMsg={t('orderNoUserSpareParts') ?? 'The order does not have spare parts from the customer'}
            />
          </CListGroupItem>
          {comment && <CListGroupItem>
            <div className={styles.comment}>
              <CSmartTable
                columns={[
                  {
                    key: 'comment',
                    label: t('orderCommentTitle') ?? 'comment',
                    filter: false,
                    sorter: false,
                  },
                ]}
                items={ [{
                  comment: comment,
                }] }
                scopedColumns={{
                  comment: (item: Item) => (
                    <td style={{ maxWidth: 0 }}>
                      {item.comment}
                    </td>
                  ),
                }}
              />
            </div>
          </CListGroupItem>}
          <CListGroupItem color="info" className="d-flex justify-content-between align-items-center">
            <OrderCertificateButton directionMenu="dropup" orderId={ id } />
            <div className="d-flex justify-content-end align-items-center">
              <CButton
                variant="outline"
                className={styles.viewOrderButton}
                disabled={isLoading}
                onClick={ (): void => onViewEditOrderClick(true) }
              >
                {isLoading && isOrderModalFormReadOnly && (<Spinner color="primary"/> )}
                {t('orderViewButton')}
              </CButton>
              <CButton
                variant="outline"
                color="danger"
                className={styles.editOrderButton}
                disabled={isLoading}
                onClick={ onPermissionAction((): void => onViewEditOrderClick(false)) }
              >
                {isLoading && !isOrderModalFormReadOnly && (<Spinner color="danger"/> )}
                {t('orderEditButton')}
              </CButton>
            </div>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  );
};

export { OrderDetails };
