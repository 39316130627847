import { EmployeeFormData } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';

const DEFAULT_EMPLOYEE_MODAL_PAYLOAD: EmployeeFormData = {
  firstName: '',
  secondName: '',
  patronymicName: '',
  birthDate: getFormattedDate(new Date(), 'dd.MM.yyyy'),
  registrationDate: getFormattedDate(new Date(), 'dd.MM.yyyy'),
  positionId: 1,
  dismissalDate: getFormattedDate(new Date(), 'dd.MM.yyyy'),
  statusId: 1,
  salaryTypes: [],
  employeeInfo: '',
  phone: '',
  email: '',
  viber: '',
  telegram: '',
};

export { DEFAULT_EMPLOYEE_MODAL_PAYLOAD };
