import { CCardBody, CCardTitle, CFormInput, CFormSelect } from '@coreui/react-pro';
import { CarServiceEditRequestDto } from 'common/types/types';
import { Icon } from 'components/common/common';
import { TextArea } from 'components/textarea/text-area';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceEditRequestDto>,
  disabled?: boolean,
  value: string,
};

const InfoCard: FC<Props> = ({ register, disabled, value }) => {

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>Information</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ paddingBottom: 8 }}>Logo</div>
            <div className={styles.photoContainer}>
              <Icon name={'photo'} className={styles.icon} />
            </div>
          </div>
          <div style={{ flexGrow: 2, paddingLeft: 16 }}>
            <div>
              <CFormInput type="text" label="Name*" name="name" />
            </div>
            <div style={{ marginTop: 15 }}>
              <CFormSelect name="statusId" label={'Status'}>
                <option value={'1'}>111</option>
                <option value={'2'}>222</option>
                <option value={'3'}>333</option>
              </CFormSelect>
            </div>
            <div style={{ marginTop: 15 }}>
              <CFormInput type="text" label="Adress*" name="name" />
            </div>
            <div style={{ marginTop: 15 }}>
              <TextArea<CarServiceEditRequestDto>
                label="Info"
                id="info"
                register={ register }
                limitSymbols={ 1024 }
                rows={3}
                nameFiled="info"
                value={ value }
                disabled={ disabled }
              />
            </div>
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { InfoCard };
