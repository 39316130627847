import {
  ApiPath,
  ContentType,
  DocumentationApiPath,
  HttpMethod,
} from 'common/enums/enums';
import { IDocumentGetByOrderId, IDocumentGetByRequestId } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class DocumentationApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  /**
   * @deprecated Use getDocById method instead
   */
  public getCertificateOfCompletedWork({ requestId }: { requestId: number }): Promise<{ url: string }> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DOCUMENTATION}${DocumentationApiPath.CERTIFICATE_OF_COMPLETED_WORK}/${requestId}`,
    );
  }

  public getDocById( payload: IDocumentGetByRequestId | IDocumentGetByOrderId ): Promise<{ url: string }> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DOCUMENTATION}${DocumentationApiPath.DOCUMENT}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { DocumentationApi };
