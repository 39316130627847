import { CButton, CCardSubtitle, CPopover, CSmartTable } from '@coreui/react-pro';
import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, NotificationType, WorkOrderTranslationKey } from 'common/enums/enums';
import { WorkOrderDto, WorkOrderTableRow } from 'common/types/types';
import { CarStateNumber, ColorItem, NoData, StatusBadge, ToggleShowDetailsBtn } from 'components/common/common';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect, useMemo, useState, useToggleTableDetails } from 'hooks/hooks';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/actions';
import { sortWorkOrder } from 'store/work-order/reducer';

import { getWorkOrderTableColumns, getWorkOrderTableData, renderWorkOrderItemDetails } from './helpers/helpers';
import styles from './style.module.scss';

type Props = {
  workOrders: WorkOrderDto[],
  onViewEditWorkOrderClick: (orderId: number, readOnly: boolean) => void
  onOrderNumberClick: (id: number) => void;
  onWorkOrderNumberClick: (id: number) => void;
  isLoadingWorkOrders: boolean,
};

const WorkOrderTable: FC<Props> = ({
  workOrders,
  onViewEditWorkOrderClick,
  onWorkOrderNumberClick,
  onOrderNumberClick,
  isLoadingWorkOrders,
}) => {

  const workOrderTableColumn = getWorkOrderTableColumns();
  const workOrderTableData = getWorkOrderTableData(workOrders);
  const { details, toggleDetails, closeDetails } = useToggleTableDetails();
  const dispatch = useAppDispatch();
  const [sorterValue, setSorterValue] = useState<SorterValue>({ column: 'orderId', state: 'desc' });
  const itemsPerPage: number[] =
    useMemo( () => getItemPerPageOptions(workOrderTableData.length), [ workOrderTableData ] );
  const { t } = useTranslation([AppTranslationKey.COMMON, AppTranslationKey.WORK_ORDER]);
  const orderStatuses = useAppSelector(({ dictionary }) => dictionary.data?.orderStatuses || []);

  useEffect(() => {
    dispatch(sortWorkOrder(sorterValue));
  }, [dispatch, sorterValue, workOrders]);

  const renderStatusBadges = (item: WorkOrderTableRow): ReactElement => {
    const status = orderStatuses.find(({ id }) => id === item.status.id) || item.status;

    return  (
      <td>
        <StatusBadge
          color={status.color}
          title={status.name}
          textColor={status.colorFont} />
      </td>
    );
  };

  const renderCarNameField = (item: WorkOrderTableRow): ReactElement => {
    return  <td>
      <CCardSubtitle>{item.carName}</CCardSubtitle>
    </td>;
  };

  const renderCarNumField = (item: WorkOrderTableRow): ReactElement => {
    return  <td>
      <CarStateNumber carStateNum={item.car.carRegNum} />
    </td>;
  };

  const renderCarColorField = (item: WorkOrderTableRow): ReactElement => {
    return  <td>
      <ColorItem
        colorCode={item.car.carColorHtmlCode ?? 'transparent'}
        size={20}
      />
    </td>;
  };

  return(
    <CSmartTable
      columns={workOrderTableColumn}
      columnSorter={{
        external: true,
        resetable: false,
      }}
      sorterValue={{ column: 'orderId', state: 'desc' }}
      items={workOrderTableData}
      loading={isLoadingWorkOrders}
      itemsPerPageSelect
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageLabel={t('itemPerPageLabel') ?? 'Елементів на сторінці'}
      pagination
      scopedColumns={{
        comment: (item: WorkOrderTableRow) => (
          <td style={{ maxWidth: 0 }}>
            <CPopover
              content={item.comment}
              placement="right"
              trigger="hover"
            >
              <div className={styles.commentColumn}>
                {item.comment}
              </div>
            </CPopover>
          </td>
        ),
        orderId: (item: WorkOrderTableRow): ReactElement =>
          <td>
            <CButton
              color="link"
              size={'sm'}
              className="px-0"
              onClick={():void => onWorkOrderNumberClick(item.orderId)}
            >
              { item.orderId }
            </CButton>
          </td>,
        requestId: (item: WorkOrderTableRow): ReactElement =>
          <td>
            <CButton
              color="link"
              size={'sm'}
              className="px-0"
              onClick={():void => onOrderNumberClick(item.requestId)}
            >
              { item.requestId }
            </CButton>
          </td>,
        status: (item: WorkOrderTableRow) => renderStatusBadges(item),
        carName: (item: WorkOrderTableRow) => renderCarNameField(item),
        carNum: (item: WorkOrderTableRow) => renderCarNumField(item),
        carColor: (item: WorkOrderTableRow) => renderCarColorField(item),
        sumFull: (item: WorkOrderTableRow) => (
          <td>
            <div>
              <span style={{ color: 'darkred' }} >{item.sumFull}</span>
            </div>
          </td>
        ),
        show_details: (item: WorkOrderTableRow) =>
          <ToggleShowDetailsBtn
            isOpen={ details.includes(item.orderId) }
            toggleDetails={(): void => toggleDetails(item.orderId)}
          />,
        details: (item ) => renderWorkOrderItemDetails(
          {
            work: item.work,
            spareParts: item.spareParts,
            clientSpareParts: item.clientSpareParts,
            visible: details.includes(item.orderId),
            handleViewEditWorkOrderClick: (readOnly) => {
              if (!readOnly && item.status.id === 7) {
                dispatch(appActions.notify(
                  {
                    type: NotificationType.ERROR,
                    message: t(
                      WorkOrderTranslationKey.EDIT_CANCELED_WORK_ORDER_ERROR_TEXT,
                      { ns: AppTranslationKey.WORK_ORDER },
                    ),
                  }));
              } else {
                closeDetails(item.orderId);
                onViewEditWorkOrderClick(item.orderId, readOnly);
              }
            },
          },
        ),
      }}
      onSorterChange={ (value: SorterValue): void => {
        setSorterValue(value);
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
        align: 'middle',
      }}
    />
  );
};

export { WorkOrderTable };
