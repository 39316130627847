import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { useAppDispatch, useAppSelector, useEffect, useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supplierActions } from 'store/actions';

import { SuppliersTable } from './suppliers-table/suppliers-table';

const Suppliers: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();
  const { t, i18n } = useTranslation('mainMenu');

  const { userId } = useAppSelector(({ auth }) => ({
    userId: auth.currentUser?.id ?? 0,
  }));

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.SUPPLIERS}:addButtonTitle`,
      action: onPermissionAction(() => navigate('add'), Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [i18n.language]);

  useEffect(() => {
    dispatch(supplierActions.getListSuppliers({ userId }));
  }, [userId, dispatch]);

  return (
    <CCard>
      <CCardHeader>
        <h5 className="text-dark">{ t('suppliers') }</h5>
      </CCardHeader>
      <CCardBody style={{ overflowX: 'auto' }}>
        <SuppliersTable />
      </CCardBody>
    </CCard>
  );
};

export { Suppliers };
