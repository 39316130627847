import { EmployeeModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { ReactElement } from 'react';

type TUseEmployeeModal = {
  getModal: () => ReactElement,
  toggleModal: (isVisible: boolean) => void,
};

type Props = {
  carServiceId: number,
};

const useEmployeeModal = ({ carServiceId }: Props): TUseEmployeeModal => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = (isVisible: boolean): void => setIsOpen(isVisible);

  const getModal = (): ReactElement => (
    <EmployeeModal
      isOpen={ isOpen }
      carServiceId={ carServiceId }
      onClose={ (): void => toggleModal(false) }
    />
  );

  return { getModal, toggleModal };
};

export { useEmployeeModal };
