import cn from 'classnames';
import { ReactElement } from 'react';
import { GroupBase } from 'react-select';
import ReactAsyncSelect from 'react-select/async';

import { TCommonAsyncSelectProps } from '../interfaces';
import styles from '../styles.module.scss';

const AsyncSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>
  ({ className, isError = false, ...other }: TCommonAsyncSelectProps<Option, IsMulti, Group>): ReactElement => (
    <ReactAsyncSelect
      { ...other }
      classNamePrefix="ucs-select"
      className={ cn(styles.uscSelect, className, { [styles.isError]: isError }) }
      menuPortalTarget={ document.getElementById('portal-container') }
      menuPosition="fixed"
      menuPlacement="auto"
    />
  );

export { AsyncSelect };
