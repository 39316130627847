import { CFooter, CLink } from '@coreui/react-pro';
import { ENV } from 'common/enums/enums';
import { ExtraButtonContextProvider } from 'contexts';
import { getYear } from 'date-fns';
import { toBase64 } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useLocation } from 'hooks/hooks';
import { FC, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { errorHandlerActions } from 'store/actions';

import { Header } from '../header/header';
import { ErrorPage } from '../page-error/error-page';
import { Sidebar } from '../sidebar/sidebar';

const MainLayout: FC = () => {

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => (state.auth));

  const fallback = (): ReactElement => {
    return <ErrorPage />;
  };

  const onError = (error: Error): void => {
    const payload = {
      name: error.name,
      message: error.message,
      stack: error.stack,
      urlPathname: location.pathname,
      urlSearch: location.search,
      deviceInfo: navigator.userAgent,
      currentUser: currentUser,
    };

    dispatch(errorHandlerActions.sendError({
      base64: toBase64(JSON.stringify(payload)),
    }));

  };

  return (
    <ErrorBoundary
      FallbackComponent={fallback}
      onError={onError}
    >
      <ExtraButtonContextProvider>
        <main>
          <Sidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <Header />
            <div className="body d-flex flex-column flex-grow-1 px-3">
              <Outlet/>
            </div>
            <CFooter>
              <div>
                <CLink href="https://t2studio.org">T2Studio</CLink>
                <span>&copy; {getYear(Date.now())} UkrCarService</span>
              </div>
              <div>
                <span> Version {ENV.APP_VERSION}</span>
              </div>
            </CFooter>
          </div>
        </main>
      </ExtraButtonContextProvider>
    </ErrorBoundary>
  );
};

export { MainLayout };
