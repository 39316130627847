import { ReactElement } from 'react';
import { components as ReactComponent,GroupBase, MenuListProps } from 'react-select';

interface IProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends MenuListProps<Option, IsMulti, Group> {
  countAllItems: number,
}

const MenuList = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, countAllItems, ...other }: IProps<Option, IsMulti, Group>): ReactElement => {
  return (
    <div>
      <ReactComponent.MenuList { ...other } >
        { children }
        {
          (countAllItems > other.options.length)
          && <div className="ucs-select__footer-option">...</div>
        }
      </ReactComponent.MenuList>
    </div>
  );
};

export { MenuList };
