import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';

import styles from './styles.module.scss';

const ContactCard: FC = () => {
  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>Contact</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label style={{ paddingBottom: 8 }}>Phone Number Main</label>
              <PhoneInput
                country={'ua'}
                countryCodeEditable={false}
                inputStyle={{ width: '100%' }}

              />
            </div>
            <div style={{ width: '45%' }}>
              <label style={{ paddingBottom: 8 }}>Phone Number</label>
              <PhoneInput
                country={'ua'}
                countryCodeEditable={false}
                inputStyle={{ width: '100%' }}
              />
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label style={{ paddingBottom: 8 }}>Viber</label>
              <PhoneInput
                country={'ua'}
                countryCodeEditable={false}
                inputStyle={{ width: '100%' }}

              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput type="text" label="Telegram" name="telegram" />
            </div>
          </div>
          <div style={{ marginTop: 18 }}>
            <CFormInput type="text" label="Email" name="email" />
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { ContactCard };
