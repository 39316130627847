import { AppTranslationKey, Pages, PersonalRoute, StorageTranslationKey } from 'common/enums/enums';
import { WithStorageTabs } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { StorageDetails } from 'components/storage-profiles/storage-details/storage-details';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withStoragesTabs } from 'hoc/hoc';
import { useEffect, useNavigate } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const StoragesProfiles: FC<WithStorageTabs> = ({ activeStorage }) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { onPermissionAction } = usePermissionToastContext();

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.STOCK}:${StorageTranslationKey.ADD_STORAGE}`,
      action: onPermissionAction(handledAddStorage, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [i18n.language]);

  const handledAddStorage = (): void => {
    navigate(PersonalRoute.ADD_STORAGE.split('/').pop() ?? 'add');
  };

  return (<StorageDetails storage={activeStorage}/>);
};

export const StorageProfilesWithStoragesTabs = withStoragesTabs(
  StoragesProfiles,
  Pages.STORAGE,
  () => t('mainMenu:storageProfiles') ?? '',
);
