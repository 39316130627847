import { CModal, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeForm } from './form/employee-form';

interface IProps {
  isOpen: boolean,
  carServiceId: number,
  onClose: () => void,
}

const EmployeeModal: FC<IProps> = ({ isOpen, carServiceId, onClose }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);

  return (
    <CModal
      visible={ isOpen }
      onClose={ onClose }
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{ `${t(EmployeesProfilesKey.MODAL_TITLE)} #` }</CModalTitle>
      </CModalHeader>
      <EmployeeForm onClose={ onClose } carServiceId={ carServiceId } />
    </CModal>
  );
};

export { EmployeeModal };
