import {
  AllCarWorkModalTranslationKey,
  AppTranslationKey,
  AuthTranslationKey,
  CarWorkTranslationKey, ClientSparePartsTranslationKey,
  DangerAlertModalTranslationKey, EmployeesProfilesKey,
  ErrorPageTranslationKey, FeedBackModalKey,
  GdrpTranslationKey, HelpAboutRequestKey,
  InfoVinTranslationKey,
  NotFoundPageTranslationKey,
  OrderCertificateKey,
  OrderModalTranslationKey,
  RecoveryPasswordTranslationKey, SelectComponentKey,
  SelectOrderModalTranslationKey,
  SelectSparePartsModalTranslationKey,
  SelectSparePartsTableTranslationKey,
  SelectWorkModalTranslationKey,
  SelectWorkToOrderModalTranslationKey,
  StorageDetailsTranslationKey,
  StorageTranslationKey,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';

export const uk = {
  breadcrumbs: {
    home: 'Домашня сторінка',
    dashboard: 'Головна сторінка',
    profile: 'Профіль',
    carService: 'СТО',
    edit: 'Редагування',
    add: 'Додавання',
    myAutoServices: 'Мої СТО',
    carServicesProfiles: 'Профілі СТО',
    carServicesCustomers: 'Клієнти',
    carServicesAuto: 'Автомобілі',
    carServicesOrders: 'Заявки',
    storage: 'Склад',
    suppliers: 'Постачальники',
    storagesProfiles: 'Профілі складів',
    work: 'Роботи',
    spareParts: 'Запчастини',
    workOrders: 'Наряди',
    videoReview: 'Відеоогляд',
    tariffs: 'Тарифи',
    services: 'Сервіси',
    infoVin: 'Інформація по VIN',
    orderCertificate: 'Акт виконаних робіт #{{orderId}}',
    help: 'Справка',
    allAboutRequest: 'Все про Заявку',
    employees: 'Співробітники',
    salary: 'Заробітна плата',
    employeesProfiles: 'Профілі співробітників',
  },
  mainMenu: {
    dashboard: 'Головна сторінка',
    myAutoServices: 'Мої СТО',
    ordersNavItem: 'Заявки',
    carsNavItem: 'Автомобілі',
    customersNavItem: 'Клієнти',
    carServicesNavItem: 'Профілі СТО',
    profile: 'Профіль',
    exit: 'Вихід',
    storage: 'Склад',
    parts: 'Запчастини',
    work: 'Роботи',
    suppliers: 'Постачальники',
    storageProfiles: 'Профілі складів',
    workOrders: 'Наряди',
    videoReview: 'Відеоогляд',
    tariffs: 'Тарифи',
    services: 'Сервіси',
    infoVin: 'Інформація по VIN',
    help: 'Справка',
    allAboutRequest: 'Все про Заявку',
    employees: 'Співробітники',
    salary: 'Заробітна плата',
    employeesProfiles: 'Профілі співробітників',
  },
  [AppTranslationKey.AUTH]: {
    titleSignIn: 'Вхід',
    subtitleSignIn: 'Увійдіть, щоб продовжити',
    secondSubtitleSignIn: 'Онлайн платформа для керування автосервісом',
    anyQuestion: 'Виникли питання або цікавить додаткова інформація ?',
    crmTitle1: 'Візьміть контроль над своїм СТО:',
    crmTitle2: 'ERP Система для ефективного ведення бізнесу',
    crmTitle3: 'Не вистачає часу на розвиток СТО?',
    crmTitle4: 'ERP Система - це ваше рішення!',
    crmOptions: 'Завдяки їй Ви зможете:',
    option1: 'Додавати заявки від клієнтів;',
    option2: 'Створювати Наряд-замовлення;',
    option3: 'Вести облік запчастин на складі;',
    option4: 'Отримати 30 днів БЕЗКОШТОВНОГО безліміту після реєстрації.',
    callMe: 'Зателефонуйте або напишіть нам',
    loginButtonText: 'Увійти',
    forgotPassword: 'Забули пароль?',
    welcomeText: 'Ласкаво просимо',
    dontHaveAccount: 'Немає облікового запису?',
    registerNowButtonText: 'Зареєструватись',
    loginErrorText: 'Вкажіть дійсну електронну пошту',
    passwordErrorText: 'Пароль не може бути пустим',
    register: 'Реєстрація',
    createAccount: 'Створіть свій акаунт',
    login: 'Електронна пошта',
    name: 'Ім\'я Прізвище',
    password: 'Пароль',
    repeatPassword: 'Повторіть пароль',
    createAccountButtonText: 'Створити акаунт',
    backToSignIn: 'Назад до входу',
    requiredPassword: 'Необхідно ввести пароль',
    requiredName: 'Необхідно ввести ім\'я',
    agreeToUsePersonalData: 'Згоден на обробку моїх персональних даних',
    agreeToUsePersonalDataError: 'Необхідна згода на обробку даних',
    machPasswordError: 'Паролі не збігаються',
    [AuthTranslationKey.REGISTRATION_MSG]: 'Вітаємо! Ваш обліковий запис успішно створено. ' +
    'Для входу до системи підтвердіть свій e-mail. Будь ласка, перевірте вказану вами під час реєстрації пошту. ' +
    'Якщо ви не отримали листа, перевірте папку "спам". За необхідності ви можете | ' +
    ' Якщо виникли труднощі з Реєстрацією або Входом напишіть ' +
    'нам | надаємо відповідь на протязі 20 - 30 хвилин.',
    [AuthTranslationKey.RESEND_ACTION_NAME]: 'відправити лист повторно.',
    [AuthTranslationKey.INACTIVE_USER__MSG] :'Ваш обліковий запис наразі не активовано. Для входу до системи ' +
    'підтвердіть свій e-mail. Ми вже відправили лист на вашу електронну пошту. Якщо ви не отримали листа, ' +
    'перевірте папку "спам". За необхідності ви можете | ' +
    ' Якщо виникли труднощі з Реєстрацією або Входом напишіть ' +
    'нам | надаємо відповідь на протязі 20 - 30 хвилин.',
    [AuthTranslationKey.ACTIVATED]: 'Ваш аккаунт активовано',
    [AuthTranslationKey.RESEND_VALIDATION_SUCCESS]: 'Лист надіслано',
  },
  [AppTranslationKey.CAR_SERVICE]: {
    allCarServiceText: 'Усі СТО',
    backCaptionButton: 'Назад',
    saveCaptionButton: 'Зберегти',
    addCarServiceCaptionButton: 'Додати СТО',
    editCarServiceTitle: 'Редагування СТО',
    addCarServiceTitle: 'Додавання СТО',
    noCarServiceTitle: 'У вас немає жодного СТО :(',
    noCarServiceText: 'Натисніть кнопку "Додати СТО", щоб створити його',
    carServiceFormFieldName: 'Назва*',
    carServiceFormFieldStatus: 'Статус*',
    carServiceFormFieldNameErrorRequired: 'Це обов`язкове поле',
    carServiceFormFieldAddress: 'Адреса*',
    carServiceFormFieldAddressErrorRequired: 'Це обов`язкове поле',
    carServiceFormFieldInfo: 'Опис',
    carServiceFormFieldInfoText: 'Залишилось {{countSymbols}} символів',
    carServiceFormFieldInfoErrorMaxLength: 'Занадто багато символів',
    errorMessageNoCarService: 'Спершу необхідно створити СТО',
    carServiceUpdate:  'СТО успішно оновлено',
    carServiceAdd: 'СТО успішно додано',
  },
  carServiceDetails: {
    carServiceDetailsName: 'Назва:',
    carServiceDetailsStatus: 'Статус:',
    carServiceDetailsAddress: 'Адреса:',
    carServiceDetailsInfo: 'Опис:',
    carServiceDetailsEditCaptionButton: 'Редагувати',
  },
  [AppTranslationKey.CAR_SERVICE_ORDER]: {
    title: 'Заявки',
    orderAccordionHeader: 'Заявки на ремонт',
    profileAccordionHeader: 'Профіль СТО',
    autoAccordionHeader: 'Автомобілі',
    customerAccordionHeader: 'Клієнти',
    addOrderCaptionButton: 'Додати заявку',
    showDetailsCaption: 'Розкрити',
    hideDetailsCaption: 'Сховати',
    orderTableColumns: '#|Авто|Дата та Час|Статус|Коментарі|Сума|Сума з нарядами|Сума без нарядів',
    orderProblemTitle: 'Роботи',
    orderSparePartsTitle: 'Запчастини',
    orderClientSparePartsTitle: 'Запчастини від замовника',
    orderCommentTitle: 'Коментарі',
    orderPerformer: 'Виконавець',
    orderViewButton: 'Перегляд',
    orderEditButton: 'Редагувати',
    order: 'Заявка',
    orderAuto: 'Авто',
    noOrdersTitle: 'У вас немає заявок',
    noOrdersText: 'Натисніть кнопку "Додати заявку", щоб створити її',
    itemsPerPageLabel: 'Елементів на сторінці',
    successEditOrderMes: 'Заявка успішно оновлена',
    openWorkErrorMes: 'Неможливо закрити заявку, за якою є невиконані роботи.',
    successAddOrderMes: 'Заявка успішно додана',
    defPerformer: 'Без виконавця',
    filters: {
      today: 'Сьогодні',
      currentWeek: 'Поточний тиждень',
      currentMonth: 'Поточний місяць',
      startDay: 'Дата початку',
      endDay: 'Дата закінчення',
      status: 'Статус...',
      apply: 'Застосувати',
      selectAllOptions: 'Вибрати всі параметри',
    },
    orderNoWork: 'Заявка не має робіт',
    orderNoSpareParts: 'Заявка не має запчастин',
    orderNoUserSpareParts: 'Заявка не має запчастин від замовника',
  },
  shortDayOfWeek: {
    sun: 'Нд',
    mon: 'Пн',
    tues: 'Вт',
    wed: 'Ср',
    thurs: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
  },
  [AppTranslationKey.ORDER_MODAL]: {
    modalTitle: 'Заявка',
    autoFieldLabel: ' Автомобіль*',
    requiredFormField: 'Це обов`язкове поле',
    dateFieldLabel: 'Планова дата*',
    timeFieldLabel: 'Плановий час*',
    performerFieldLabel: 'Виконавець',
    shallDoFieldLabel: 'Роботи',
    spareParts: 'Запчастини',
    userSpareParts: 'Запчастини від замовника',
    duties: 'Наряди',
    statusFieldLabel: 'Статус',
    commentsFieldLabel: 'Коментар',
    editButtonCation: 'Редагувати',
    saveButtonCaption: 'Зберегти',
    closeButtonCaption: 'Закрити',
    newBtn: 'Новий',
    selectBtn: 'Вибрати',
    selectCar: 'Виберіть автомобіль ...',
    noSelectCar: 'Авто не вибране',
    workName: 'Назва роботи',
    numberTourOfDuty: 'Наряд',
    performer: 'Исполнитель',
    status: 'Статус',
    [OrderModalTranslationKey.ORDER_NO_WORK]: 'Заявка не має робіт',
    [OrderModalTranslationKey.ADD_NEW_WORK_ORDER]: 'Додати наряд',
    [OrderModalTranslationKey.TOTAL_SUM_ORDER]: 'Сума заявки',
    [OrderModalTranslationKey.TOTAL_SUM_WORKS_OF_ORDER]: 'Сума робіт',
    [OrderModalTranslationKey.TOTAL_SUM_SPARE_PARTS_OF_ORDER]: 'Сума запчастин',

  },
  [AppTranslationKey.CAR_MODAL]: {
    modalTitle: 'Автомобіль',
    vin: 'VIN',
    brand: 'Бренд',
    model: 'Модель',
    bodyType: 'Тип кузова',
    color: 'Колір',
    regNum: 'Реєстраційний номер',
    createYear: 'Рік випуску',
    mileageKm: 'Пробіг (км)',
    carInfo: 'Додаткова інформація',
    saveButtonCaption: 'Зберегти',
    selectBrand: 'Виберіть бренд ...',
    selectModel: 'Виберіть модель ...',
    selectBodyType: 'Виберіть тип кузова ...',
    selectColor: 'Виберіть колір ...',
    successCreateCarMes: 'Автомобіль успішно доданий',
    successModifyCarMes: 'Автомобіль успішно оновлено',
    requiredFormField: 'Це обов`язкове поле',
    closeButtonCaption: 'Закрити',
    editButtonCation: 'Редагувати',
    modalListTitle: 'Автомобілі',
    addNewCarBtn: 'Додати новий автомобіль',
    selectBtn: 'Вибрати',
    findBtnText: 'Пошук',
    searchBtnErrorText: 'Введіть реєстраційний номер та повторіть пошук',
    table: {
      regNum: 'Держ. №',
      brand: 'Марка',
      model: 'Модель',
      info: 'Доп. інфо',
    },
    selectCarText: 'Обрано: [{{ car }}]',
    noSelectCarText: 'Автомобіль не обрано',
    capacity: 'Об\'єм двигуна (см³)',
    fillFormModalHeader: 'Оновлення даних автомобіля',
    fillFormModalBody: 'Інформація про автомобіль успішно знайдена! ' +
      'Ви можете заповнити лише незаповнені поля або замінити всі поточні дані на нові.',
    fillFormBtnClose: 'Закрити',
    fillFormBtnConfirm: 'Заповнити пусті',
    fillFormBtnConfirmAll: 'Замінити всі',
    errorSelectBrandMessage: 'Спершу необхідно вибрати бренд автомобіля',
  },
  [AppTranslationKey.PROFILE]: {
    title: 'Профіль',
    login: 'Логін:',
    name: 'Ім\'я Прізвище:',
    dateProfile: 'Дата реєстрації:',
    tariffName: 'Поточний тариф:',
    tariffStatus: 'Статус:',
    tariffStart: 'Дата підключення:',
    tariffEnd: 'Дата закінчення:',
    changePassword: 'Змінити пароль',
    selectTariff: 'Обрати тариф',
    noData: 'Немає інформації',
    activeStatus: 'Активний',
    paidStatus: 'Оплачений',
    endStatus: 'Закінчився',
    requiredName: 'Необхідно ввести ім\'я',
    errorPhone: 'Недійсний номер телефону',
    phone: 'Телефон:',
    changePasswordButton: 'Змінити пароль',
    changePasswordModal: {
      title: 'Заміна пароля авторизації',
      saveButton: 'Змінити',
      closeButton: 'Закрити',
      oldPasswordTitle: 'Введіть старий пароль',
      newPasswordTitle: 'Введіть новий пароль',
      repeatNewPasswordTitle: 'Повторіть пароль',
      passwordPlaceholder: 'Пароль',
      passwordErrorText: 'Пароль не може бути пустий',
      matchNewPasswordError: 'Пароль співпадає з поточним',
      matchPasswordError: 'Паролі не співпадають',
      successMessage: 'Пароль змінено',
    },
  },
  [AppTranslationKey.CAR_SERVICE_AUTO]: {
    title: 'Автомобілі',
    addCarCaptionButton: 'Додати авто',
    noCarsTitle: 'У вас немає автомобілів',
    noCarsText: 'Натисніть кнопку "Додати авто", щоб створити його',
    carTableColumns: '#|Держ. №|Марка|Модель|Колір|VIN',
    showDetailsCaption: 'Розкрити',
    hideDetailsCaption: 'Сховати',
    carBodyTypeName: 'Тип кузова',
    createYear: 'Рік випуску',
    mileageKm: 'Пробіг в км',
    carInfo: 'Додаткова інф.',
    itemsPerPageLabel: 'Елементів на сторінці',
    vinCode: 'vin-код',
    carViewButton: 'Перегляд',
    carEditButton: 'Редагувати',
    capacity: 'Об\'єм двигуна (см³)',
  },
  [AppTranslationKey.CAR_SERVICE_CUSTOMER]: {
    addCustomerCaptionButton: 'Додати клієнта',
    noCustomersTitle: 'У вас немає клієнтів',
    noCustomersText: 'Натисніть кнопку "Додати клієнта", щоб створити його',
  },
  widgets: {
    title: 'Робочий стіл та аналітика',
    countAllTask: 'Кількість заявок',
    countOpenTask: 'Кількість відкритих заявок',
    countAllCars: 'Кількість авто',
  },
  [AppTranslationKey.RECOVERY_PASSWORD]: {
    [RecoveryPasswordTranslationKey.TITLE_RECOVERY_PASSWORD]: 'Відновлення паролю',
    [RecoveryPasswordTranslationKey.SUBTITLE_RECOVERY_PASSWORD]: 'Введіть свою адресу електронної пошти',
    [RecoveryPasswordTranslationKey.PLACEHOLDER_RECOVERY_PASSWORD]: 'Електронна пошта',
    [RecoveryPasswordTranslationKey.EMAIL_ERROR_TEXT]: 'Вкажіть дійсну електронну пошту',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_BUTTON_TEXT]: 'Відновити',
    [RecoveryPasswordTranslationKey.WELCOME_TEXT]: 'Підтвердіть обліковий запис',
    [RecoveryPasswordTranslationKey.CHANGE_PASS_TEXT]: 'Створіть новий пароль для облікового запису',
    [RecoveryPasswordTranslationKey.BACK_BUTTON_TEXT]: 'Назад до входу',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_TEXT]:
      `Введіть Email, який ви використовували
      при реєстрації, ми надішлемо вам посилання для зміни паролю`,
    [RecoveryPasswordTranslationKey.CONFIRM_MSG]:
      `Посилання на скидання пароля було надіслано.
      Перевірте Вашу поштову скриньку. Якщо Ви не отримали лист, будь ласка, перевірте спам.`,
    [RecoveryPasswordTranslationKey.BACK_TO_SIGN_IN]: 'Назад до входу',
    [RecoveryPasswordTranslationKey.REQUEST_ERROR_MSG]: 'Запит не було надіслано',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_TITLE_TEXT]: 'Заміна паролю авторизації',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_SUBTITLE_TEXT]: 'Введіть новий пароль',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_PLACEHOLDER]: 'Пароль',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_BUTTON_TEXT]: 'Змінити',
    [RecoveryPasswordTranslationKey.NEW_PASSWORD_ERROR_TEXT]: 'Пароль не може бути пустим',
    [RecoveryPasswordTranslationKey.ANY_DIFFICULTY]: 'Виникли труднощі?',
    [RecoveryPasswordTranslationKey.ANY_QUESTION]: 'Виникли питання?',
    [RecoveryPasswordTranslationKey.CALL_ME]: 'Зателефонуйте або напишіть нам',
  },
  [AppTranslationKey.SUPPLIERS]: {
    backCaptionButton: 'Назад',
    saveCaptionButton: 'Зберегти',
    noDataTitle: 'У Вас немає постачальників :(',
    noDataText: 'Натисніть кнопку "Додати постачальника" щоб створити нового',
    addButtonTitle: 'Додати постачальника',
    addSupplierTitle: 'Додавання постачальника',
    editSupplierTitle: 'Редагування постачальника',
    fieldInfoLabel: 'Опис постачальника',
    fieldStatusLabel: 'Статус постачальника',
    fieldAddressLabel: 'Адреса постачальника',
    fieldNameLabel: 'Найменування постачальника',
    selectStatus: 'Виберіть статус ...',
    createSupplierSuccessMessage: 'Постачальник успішно створений!',
    editSupplierSuccessMessage: 'Постачальник успішно оновлений!',
    tableColumns: '#|Найменування|Статус|Адреса',
    requiredFormField: 'Це обов`язкове поле',
    itemsPerPageLabel: 'Елементів на сторінці',
  },
  [AppTranslationKey.COMMON]: {
    feedbackBtnTitle: 'Підтримка',
    feedback: 'Задати питання, повідомити про проблему',
    textAreaText: 'Залишилось {{countSymbols}} символів',
    textAreaErrorMaxLength: 'Занадто багато символів',
    showDetailsCaption: 'Розкрити',
    hideDetailsCaption: 'Сховати',
    editButton: 'Редагувати',
    selectBtn: 'Змінити',
    saveBtn: 'Зберегти',
    closeBtn: 'Закрити',
    cancelBtn: 'Відміна',
    removeBtn: 'Видалити',
    permissionMessage: 'Щоб продовжити користуватися всіма можливостями системи, слід підключити тарифний план',
    itemPerPageLabel: 'Елементів на сторінці',
    uah: 'грн.',
    noItemsFoundTitle: 'Нічого не знайдено',
    noItemsFoundMsg: 'Спробуйте змінити умови пошуку',
    docButtonCaption: 'Документація',
  },
  [AppTranslationKey.STOCK]: {
    [StorageTranslationKey.ADD_STORAGE]: 'Додати склад',
    [StorageTranslationKey.NO_DATA_TITLE]: 'У Вас немає жодного Складу :(',
    [StorageTranslationKey.NO_DATA_TEXT]: 'Натисніть кнопку "Додати склад" щоб створити його',
    [StorageTranslationKey.ADD_STORAGE_TITLE]: 'Додавання Складу',
    [StorageTranslationKey.EDIT_STORAGE_TITLE]: 'Редагування Складу',
    [StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED]: 'Це обов`язкове поле',
    [StorageTranslationKey.STORAGE_FORM_NAME_LABEL]: 'Назва*',
    [StorageTranslationKey.STORAGE_FORM_STATUS_LABEL]: 'Статус',
    [StorageTranslationKey.STORAGE_FORM_ADDRESS_LABEL]: 'Адреса*',
    [StorageTranslationKey.STORAGE_FORM_TOO_MANY_CHAR]: 'Занадто багато символів',
    [StorageTranslationKey.STORAGE_FORM_INFO_LABEL]: 'Опис',
    [StorageTranslationKey.STORAGE_FORM_INFO_TEXT]: 'Залишилось {{countSymbols}} символів',
    [StorageTranslationKey.BACK_CAPTION_BUTTON]: 'Назад',
    [StorageTranslationKey.SAVE_CAPTION_BUTTON]: 'Зберегти',
    [StorageTranslationKey.ADD_STORAGE_SUCCESS]: 'Склад успішно додано!',
    [StorageTranslationKey.UPDATE_STORAGE_SUCCESS]: 'Склад успішно оновлено!',
  },
  [AppTranslationKey.STOCK_DETAILS]: {
    [StorageDetailsTranslationKey.STORAGE_DETAILS_NAME_FIELD]: 'Назва',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_STATUS_FIELD]: 'Статус',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_ADDRESS_FIELD]: 'Адреса',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_INFO_FIELD]: 'Опис',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_EDIT_BUTTON]: 'Редагувати',
  },
  [AppTranslationKey.CAR_WORK]: {
    [CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON]: 'Редагувати обрані роботи',
    [CarWorkTranslationKey.NO_CAR_WORK_TITLE]: 'У вас немає робот',
    [CarWorkTranslationKey.NO_CAR_WORK_TEXT]:
      'Натисніть кнопку "Редагувати обрані роботи", щоб добавити/видалити роботу з загального довідника робот',
    [CarWorkTranslationKey.CAR_WORK_TABLE_COLUMNS]:
      'Назва роботи|Тип роботи|Час, хв|Вартість|Інформація',
    [CarWorkTranslationKey.FAV_CAR_WORK_NAME]: 'Назва роботи:',
    [CarWorkTranslationKey.UPDATE_SUCCESS]: 'Дані успішно оновлено',
    [CarWorkTranslationKey.FAV_CAR_WORK_TYPE]: 'Тип роботи:',
    [CarWorkTranslationKey.FAV_CAR_WORK_TIME]: 'Час в хвилинах:',
    [CarWorkTranslationKey.FAV_CAR_WORK_COST]: 'Вартість:',
    [CarWorkTranslationKey.FAV_CAR_WORK_INFO]: 'Інформація:',
    [CarWorkTranslationKey.VALUE_LESS_ZERO]: 'Значення не може бути меньше 0',
    [CarWorkTranslationKey.INVALID_NUMBER]: 'Невірне значення',
    [CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL]: 'Елементів на сторінці',
    [CarWorkTranslationKey.ALL_CAR_WORK_MODAL]: {
      [AllCarWorkModalTranslationKey.ALL_CAR_WORK_MODAL_TITLE]: 'Довідник сервісних робіт',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME]: 'Назва роботи',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_TYPE]: 'Тип роботи',
      [AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL]: 'Наряд',
      [AllCarWorkModalTranslationKey.CAR_WORK_PRICE_MODAL]: 'Ціна',
      [AllCarWorkModalTranslationKey.CAR_WORK_QTY_MODAL]: 'Кількість',
      [AllCarWorkModalTranslationKey.CAR_WORK_SUM_MODAL]: 'Сума',
      [AllCarWorkModalTranslationKey.FEEDBACK_BUTTON_TITLE]: 'Не знайшли потрібної роботи ?',
    },
    [CarWorkTranslationKey.EDIT_CAR_WORK_MODAL_TITLE]: 'Редагування роботи',
    [CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL]: {
      [SelectWorkToOrderModalTranslationKey.TITLE]: 'Роботи по заявці #',
      [SelectWorkToOrderModalTranslationKey.SELECTED]: 'Обрані',
      [SelectWorkToOrderModalTranslationKey.SUM]: 'Сума робіт',
    },
  },
  [AppTranslationKey.SPARE_PARTS]: {
    addButtonTitle: 'Додати запчастину',
    createSuccessMessage: 'Запчастина успішно створена!',
    editSuccessMessage: 'Запчастина успішно оновлена!',
    createModalTitle: 'Нова запчастина',
    editModalTitle: 'Редагування запчастини',
    name: 'Найменування*',
    sparePartName: 'Назва запчастини',
    orderId: 'Наряд',
    priceOne: 'Ціна',
    count:'Кількість',
    priceTotal:'Сума',
    addInfo: 'Додаткова інформація',
    supplier: 'Постачальник*',
    valueCurrent: 'Кількість на складі*',
    priceSell: 'Вартість продажна*',
    valueMin: 'Мінімальна кількість*',
    originalCode: 'Оригінальний код',
    requiredFormField: 'Це обов`язкове поле',
    selectSupplier: 'Виберіть постачальника ...',
    noDataTitle: 'У вас немає жодної Запчастини :(',
    noDataText: 'Натисніть кнопку "Додати запчастину" щоб створити її',
    tableColumns: '#|Найменування|Кількість|Мінімальна кількість|Вартість|Код',
    itemPerPageLabel: 'Елементів на сторінці',
    noStoreErrorMes: 'Спершу необхідно створити склад',
  },
  [AppTranslationKey.GDRP_MODAL]: {
    [GdrpTranslationKey.TITLE]: 'Політика конфіденційності та обробки даних користувачів',
    [GdrpTranslationKey.CLOSE_BUTTON_CAPTION]: 'Закрити',
  },
  [AppTranslationKey.WORK_ORDER]: {
    [WorkOrderTranslationKey.TITLE]: 'Наряди',
    [WorkOrderTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} від {{date}} {{carInfo}}',
    [WorkOrderTranslationKey.CREATE_CANCELED_WORK_ORDER_ERROR_TEXT]: 'Неможливо створити наряд у статусі «Скасований»',
    [WorkOrderTranslationKey.EDIT_CANCELED_WORK_ORDER_ERROR_TEXT]: 'Редагування скасованого наряду неможливе. ' +
    'Створіть новий наряд',
    [WorkOrderTranslationKey.ADD_WORK_ORDER]: 'Додати наряд',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_WORK_TITLE]: 'Роботи',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_SPARE_PARTS_TITLE]: 'Запчастини',
    [WorkOrderTranslationKey.WORK_ORDER_NO_WORK]: 'Наряд не має робіт',
    [WorkOrderTranslationKey.WORK_ORDER_NO_SPARE_PARTS]: 'Наряд не має запчастин',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TITLE]: 'У вас немає нарядів',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TEXT]:
      'Натисніть кнопку "Додати наряд", щоб створити його',
    [WorkOrderTranslationKey.NUM_ORDER]: 'Заявка',
    [WorkOrderTranslationKey.DATE_TIME]: 'Дата та Час',
    [WorkOrderTranslationKey.AUTO]: 'Авто',
    [WorkOrderTranslationKey.COMMENT]: 'Коментарі',
    [WorkOrderTranslationKey.SUM_FULL]: 'Сума',
    [WorkOrderTranslationKey.SUM_WORKS]: 'Сума робіт',
    [WorkOrderTranslationKey.SUM_SPARE_PARTS]: 'Сума запчастин',
    [WorkOrderTranslationKey.STATUS]: 'Статус',
    [WorkOrderTranslationKey.ADD_SUCCESS]: 'Наряд доданий',
    [WorkOrderTranslationKey.EDIT_SUCCESS]: 'Наряд успішно оновлено',
    [WorkOrderTranslationKey.TOTAL_WORK_ORDER_SUM]: 'Сума наряду',
    [WorkOrderTranslationKey.WORK_ORDER_MODAL]: {
      [WorkOrderModalTranslationKey.TITLE]: 'Наряд',
      [WorkOrderModalTranslationKey.STATUS_LABEL]: 'Статус',
      [WorkOrderModalTranslationKey.ORDER]: 'Заявка*',
      [WorkOrderModalTranslationKey.DATE]: 'Дата',
      [WorkOrderModalTranslationKey.TIME]: 'Час',
      [WorkOrderModalTranslationKey.NO_DATA_MSG]: 'Немає даних',
      [WorkOrderModalTranslationKey.SELECT_BTN_CAPTION]: 'Вибрати',
      [WorkOrderModalTranslationKey.REQUIRED]: 'Це обов\'язкове поле',
      [WorkOrderModalTranslationKey.WORK_NAME_TABLE_FIELD]: 'Назва роботи',
      [WorkOrderModalTranslationKey.SPARE_PART_NAME_TABLE_FIELD]: 'Назва запчатини',
      [WorkOrderModalTranslationKey.QTY_TABLE_FIELD]: 'Кількість',
      [WorkOrderModalTranslationKey.COST_TABLE_FIELD]: 'Ціна',
      [WorkOrderModalTranslationKey.SUM_TABLE_FIELD]: 'Сума',
      [WorkOrderModalTranslationKey.NO_BIND_ORDER_ERROR_MSG]: 'Необхідно обрати заявку',
    },
  },
  [AppTranslationKey.TARIFFS] :{
    title: 'Перегляд тарифів',
    testTariffName: 'Пробний безліміт',
    minTariffName: 'Мінімум',
    standardTariffName: 'Стандарт',
    unlimitedTariffName: 'Безліміт',
    premiumTariffName: 'Преміум',
    countRequests: 'Кількість відкритих заявок',
    works: 'Роботи',
    workOutfit: 'Наряд-замовлення',
    provider: 'Постачальники',
    storage: 'Склад',
    calculateCost: 'Розрахувати вартість тарифу',
    period: 'Термін підписки',
    month: 'міс',
    subscribeButton: 'Підключити',
    calculatePrice: 'Розрахувати вартість',
    periodSubscribers: 'Термін підписки {{countMonth}} / міс',
    descriptionPayment: 'Користування UkrCarService. Тариф: {{tariffName}} - {{countMonth}} міс',
    currencyUAH: 'грн.',
    disableButtonText: 'Недоступно',
    activePanelText: 'Активний до {{endDate}}',
    planedPanelText: 'Запланований з {{startDate}}',
    finishedPanelText: 'Закінчився {{endDate}}',
    calcPrice: 'Розрахувати вартість',
    addInfo: {
      partnership: {
        info1: 'Термін дії - необмежений за умови:',
        info2: 'активного використання ERP-системи',
        info3: 'наданні відгуків та пропозицій щодо функціоналу',
      },
      testUnlimited: {
        info1: 'Термін підписки - 1 міс',
        info2: 'Активується при створенні першого СТО',
        info3: 'Активується при створенні першого складу',
      },
    },
  },
  [AppTranslationKey.HELP] :{
    title: 'Відеоогляд системи',
    video_title: 'Відеоогляд на поточний момент',
  },
  [AppTranslationKey.SELECT_ORDER_MODAL]: {
    [SelectOrderModalTranslationKey.TITLE] : 'Заявки',
    [SelectOrderModalTranslationKey.NO_ORDER_SELECTED] : 'Заявка не вибрана',
    [SelectOrderModalTranslationKey.AUTO_TABLE_FIELD] : 'Авто',
    [SelectOrderModalTranslationKey.DATETIME_TABLE_FIELD] : 'Дата та Час',
    [SelectOrderModalTranslationKey.COMMENT_TABLE_FIELD] : 'Коментар',
    [SelectOrderModalTranslationKey.STATUS_TABLE_FIELD] : 'Статус',
    [SelectOrderModalTranslationKey.ALERT_ORDER_TITLE] : 'Заявка:',
    [SelectOrderModalTranslationKey.ALERT_AUTO_TITLE] : 'Авто:',
    [SelectOrderModalTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} від {{date}}',
  },
  [AppTranslationKey.SELECT_WORK_MODAL]: {
    [SelectWorkModalTranslationKey.NO_SELECT]: 'Немає вибраних робіт',
    [SelectWorkModalTranslationKey.SELECTED]: 'Вибрано',
    [SelectWorkModalTranslationKey.SELECT_WORK_TITLE]: 'Вибрати роботу',
    [SelectWorkModalTranslationKey.SELECT_WORK_SUM]: 'Сума робіт',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_MODAL]: {
    [SelectSparePartsModalTranslationKey.TITLE]: 'Запчастини',
    [SelectSparePartsModalTranslationKey.SELECTED]: 'Вибрано',
    [SelectSparePartsModalTranslationKey.NO_SELECT]: 'Немає вибраних запчастин',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_TABLE]: {
    [SelectSparePartsTableTranslationKey.NO_SPARE_PARTS_WORK_TITLE]: 'У вас немає запчастин',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_NAME]: 'Назва',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_STOCK_NAME]: 'Склад',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_QTY]: 'Кількість',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_PRICE]: 'Ціна',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_SUM]: 'Сума',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_COUNTERPARTY_NAME]: 'Постачальник',
    [SelectSparePartsTableTranslationKey.TOTAL_SPARE_PARTS_SUM]: 'Сума',
  },
  [AppTranslationKey.DANGER_ALERT_MODAL]: {
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_TEXT]:
      'Ви впевнені у скасуванні наряду? Всі запчастини повернуться на склад, ' +
      'а наряд більше не буде доступний для редагування.',
    [DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT]: 'Назад',
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_BUTTON_TEXT]: 'Відхилити наряд',
    [DangerAlertModalTranslationKey.DELETE_PHONE_TEXT]: 'Ви впевнені що хочете видалити телефон?',
    [DangerAlertModalTranslationKey.DELETE_PHONE_BUTTON_TEXT]: 'Видалити телефон',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_TEXT]:
      'Ви впевнені у відхиленні заявки? Всі наряди будуть скасовані, а запчастини повернуться на склад.',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_BUTTON_TEXT]: 'Відхилити заявку',
  },
  [AppTranslationKey.INFO_VIN]: {
    [InfoVinTranslationKey.TITLE]: 'Перегляд інформації по VIN',
    [InfoVinTranslationKey.SEARCH_BUTTON_TEXT]: 'Пошук',
    [InfoVinTranslationKey.SEARCH_INPUT_TITLE]: 'Пошук інформації про автомобіль',
    [InfoVinTranslationKey.VIN_INPUT_PLACEHOLDER]: 'Введіть VIN',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_PLACEHOLDER]: 'Введіть держ. номер',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_ACTIVE_PLACEHOLDER]: 'АА0000АА',
    [InfoVinTranslationKey.ERROR_SHORT_VIN]: 'VIN занадто короткий. Він повинен містити 17 символів',
    [InfoVinTranslationKey.ERROR_LONG_VIN]: 'VIN занадто довгий. Він повинен містити 17 символів',
    [InfoVinTranslationKey.ERROR_REG_NUM]: 'Держ. номер повинен бути дійсним',
    [InfoVinTranslationKey.ERROR_EMPTY_SEARCH]: 'Необхідно заповнити хоча б одне з полів: Він-код або державний номер',
    [InfoVinTranslationKey.TABLE_INFO_TITLE]: 'Інформація про автомобіль',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TITLE]: 'Отримайте інформацію про автомобіль за допомогою VIN-коду ' +
      'або державного номеру просто та швидко!',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TEXT]: '<p>Введіть VIN-код або державний номер транспортного засобу ' +
      'у відповідне поле пошуку вище, щоб отримати технічні характеристики. Зверніть увагу, що система надає ' +
      'інформацію за VIN-кодом лише для транспортних засобів, зареєстрованих або перереєстрованих після 2019 року. ' +
      'Пошук за державним номером включає в себе значно більший обсяг автомобілів.</p>' +
      '<p><bold>Як правильно ввести VIN-код та державний номер в пошукове поле?</bold> ' +
      'Наша система пошуку забезпечує легкість при введенні даних та швидкість обробки вашого запиту.</p>' +
      '<ul><li>VIN-код та Державний номер можна вводити як великими, так і маленькими символами, ' +
      'з пробілами або без них. Якщо дані містять тире “-”, його необхідно вписувати.</li>' +
      '<li>Спірні літери, такі як "і", "а", "о", "В", "К", “е”, та інші, можна вводити як кирилицею, ' +
      'так і латиницею.</li></ul>',
    [InfoVinTranslationKey.TABLE_FIELD_CAR]: 'Авто',
    [InfoVinTranslationKey.TABLE_FIELD_VIN]: 'VIN',
    [InfoVinTranslationKey.TABLE_FIELD_YEAR]: 'Рік випуску',
    [InfoVinTranslationKey.TABLE_FIELD_COLOR]: 'Колір',
    [InfoVinTranslationKey.TABLE_FIELD_BODY]: 'Тип кузова',
    [InfoVinTranslationKey.TABLE_FIELD_FUEL]: 'Тип палива',
    [InfoVinTranslationKey.TABLE_FIELD_CAPACITY]: 'Об’єм двигуна',
    [InfoVinTranslationKey.TABLE_FIELD_OWN_WEIGHT]: 'Власна вага',
    [InfoVinTranslationKey.TABLE_FIELD_TOTAL_WEIGHT]: 'Загальна вага',
    [InfoVinTranslationKey.TABLE_FIELD_REG_NUM]: 'Держ. номер',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TITLE]: 'Що таке VIN-код?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TEXT]: '<0>VIN</0> (Vehicle Identification Number), також відомий як ' +
      '“номер кузова” - є унікальною комбінацією символів, яка ідентифікує автомобіль. Цей ідентифікаційний номер ' +
      'призначається виробником з метою ідентифікації кожного окремого транспортного засобу.',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TITLE]: 'Де знаходиться VIN-код?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TEXT]: 'Крім техпаспорта, VIN-код автомобіля можна ' +
      'знайти на ідентифікаційній табличці, яка зазвичай розташована у спеціальному віконці біля лобового скла ' +
      'зі сторони водія, на стійці передньої лівої двері, під обшивкою підлоги поруч із сидіннями попереду ' +
      'або під капотом чи арками коліс.',
  },
  [AppTranslationKey.ERROR_PAGE]: {
    [ErrorPageTranslationKey.TITLE]: 'Щось пішло не так...',
    [ErrorPageTranslationKey.BACK_TO_MAIN]: 'Повернутися на головну',
    [ErrorPageTranslationKey.CONTACT_US_TEXT]: 'Якщо помилка повторюється, будь ласка,',
    [ErrorPageTranslationKey.CONTACT_US_LINK_TEXT]: 'зв\'яжіться з нашою підтримкою',
    [ErrorPageTranslationKey.MSG]:
      '<p>На жаль, сталася помилка під час обробки вашого запиту.' +
      ' Ми вже працюємо над вирішенням цієї проблеми. Будь ласка, спробуйте ще раз пізніше.</p>' +
      '<p><bold>Рекомендовані дії:</bold></p>' +
      '<ul><li>Перезавантажте сторінку і повторіть спробу.</li>' +
      '<li>Перевірте ваше з\'єднання з Інтернетом і спробуйте знову.</li>' +
      '</ul>' +
      '<p><bold>Додаткова допомога:</bold></p>' +
      '<ul>' +
      '<li>Якщо проблема залишається, ' +
      '<contactUs>зв\'яжіться з нашою підтримкою</contactUs> ' +
      'або зателефонуйте до нас на гарячу лінію 0 800 332 308.</li>' +
      '</ul>',
  },
  [AppTranslationKey.NOT_FOUND_PAGE]: {
    [NotFoundPageTranslationKey.TITLE]: 'На жаль! Ви загубилися.',
    [NotFoundPageTranslationKey.MSG]: 'Сторінка, яку ви шукаєте, не знайдена',
  },
  [AppTranslationKey.ORDER_CERTIFICATE]: {
    [OrderCertificateKey.PAGE_TITLE]: 'Перегляд акту виконаних робіт',
    [OrderCertificateKey.TITLE]: 'Акт виконаних робіт {{num}}',
    [OrderCertificateKey.PERFORMER_SIDE_TITLE]: 'Виконавець',
    [OrderCertificateKey.CUSTOMER_SIDE_TITLE]: 'Замовник',
    [OrderCertificateKey.NAME_SERVICE_STATION]: 'Назва СТО',
    [OrderCertificateKey.ADDRESS_SERVICE_STATION]: 'Адреса',
    [OrderCertificateKey.PHONE_SERVICE_STATION]: 'Тел',
    [OrderCertificateKey.BRAND_CAR]: 'Марка',
    [OrderCertificateKey.MODEL_CAR]: 'Модель',
    [OrderCertificateKey.GOV_NUMBER_CAR]: 'Держ. номер',
    [OrderCertificateKey.COMPLETED_WORKS_TABLE_TITLE]: 'Виконані роботи',
    [OrderCertificateKey.NAME_WORKS]: 'Назва роботи',
    [OrderCertificateKey.QUANTITY]: 'Кількість',
    [OrderCertificateKey.PRICE]: 'Ціна',
    [OrderCertificateKey.SUM]: 'Сума',
    [OrderCertificateKey.UNIT]: 'шт',
    [OrderCertificateKey.UAH]: 'грн',
    [OrderCertificateKey.TOTAL]: 'Разом',
    [OrderCertificateKey.SPARE_PARTS_TABLE_TITLE]: 'Використані запчастини (матеріали), оплачені замовником',
    [OrderCertificateKey.NAME_SPARE_PARTS]: 'Назва запчастини',
    [OrderCertificateKey.CODE]: 'Код',
    [OrderCertificateKey.SPARE_PARTS_CLIENT_TABLE_TITLE]: 'Використані запчастини (матеріали), прийняті від замовника',
    [OrderCertificateKey.TOTAL_TABLE_TITLE]: 'Загальна вартість',
    [OrderCertificateKey.TOTAL_PRICE_WORKS]: 'Вартість робіт',
    [OrderCertificateKey.TOTAL_PRICE_SPARE_PARTS]: 'Вартість запчастин',
    [OrderCertificateKey.TOTAL_PRICE]: 'Всього до оплати',
    [OrderCertificateKey.FOOTER_TITLE]: 'Зауваження   та рекомендації клієнту щодо обслуговування/ремонту ' +
      'та експлуатації  ТЗ',
    [OrderCertificateKey.VEHICLE_CONFIRM]: 'Транспортний засіб здав',
    [OrderCertificateKey.CONDITIONS_CONFIRM]: 'З   обсягом та вартістю робіт згоден. Транспортний засіб прийняв, ' +
      'претензій до комплектності ДЗ не маю',
    [OrderCertificateKey.PERFORMER_SIGN_TITLE]: 'Від виконавця',
    [OrderCertificateKey.CUSTOMER_SIGN_TITLE]: 'Від замовника',
    [OrderCertificateKey.FULL_NAME]: 'Прізвище та ім\'я',
    [OrderCertificateKey.SIGNATURE]: 'підпис',
    [OrderCertificateKey.DATE]: 'Дата',
    [OrderCertificateKey.DOWNLOAD_PDF]: 'Завантаження PDF',
    [OrderCertificateKey.BACK_BUTTON]: 'Назад',
  },
  [AppTranslationKey.CLIENT_SPARE_PARTS]: {
    [ClientSparePartsTranslationKey.NOT_ADDED_SPARE_PART_ERROR]: 'Будь ласка, натисніть "Додати" або очистіть поля',
    [ClientSparePartsTranslationKey.TITLE]: 'Запчастини від замовника',
    [ClientSparePartsTranslationKey.NAME]: 'Назва',
    [ClientSparePartsTranslationKey.COUNT]: 'Кількість',
    [ClientSparePartsTranslationKey.ADD_SPARE_PART_TITLE]: 'Додати',
    [ClientSparePartsTranslationKey.ADDED]: 'Додано',
    [ClientSparePartsTranslationKey.NO_SPARE_PARTS]: 'Немає запчастин від замовника',
    [ClientSparePartsTranslationKey.CLOSE]: 'Закрити',
    [ClientSparePartsTranslationKey.SAVE]: 'Зберегти',
    [ClientSparePartsTranslationKey.REQUIRED]: 'Це обов\'язкове поле',
    [ClientSparePartsTranslationKey.EMPTY_SPARE_PARTS_LIST_ERROR]:
      'Форма не може бути порожня. Додайте хочаб одну запчастину',
    [ClientSparePartsTranslationKey.SUCCESS_ADDED_SP]: 'Запчастини заявки успішно змінено.',
  },
  [AppTranslationKey.FEED_BACK_MODAL]: {
    [FeedBackModalKey.TITLE]: 'Форма відгуків, запитань, пропозицій',
    [FeedBackModalKey.DEFAULT_PLACEHOLDER]: 'Вміст повідомлення...',
    [FeedBackModalKey.SEND_BUTTON_TITLE]: 'Відправити',
    [FeedBackModalKey.ERROR_MESSAGE]: 'Виникла помилка при відправці листа',
    [FeedBackModalKey.SUCCESS_MESSAGE]: 'Дякуємо за звернення! Відповідь отримаєте на ваш email: {{email}}',
    [FeedBackModalKey.WARNING_FILES_SIZE]: 'Загальний розмір файлів має бути менше 25МБ.',
    [FeedBackModalKey.MB]: 'МБ',
    [FeedBackModalKey.KB]: 'КБ',
  },
  [AppTranslationKey.HELP_ABOUT_REQUEST]: {
    [HelpAboutRequestKey.TITLE]: 'При яких статусах заявка вважається відкритою ?',
    [HelpAboutRequestKey.DESCRIPTION]: 'Система використовує ряд статусів для відображення поточного етапу ' +
    'обслуговування автомобіля. Для користувачів які оформили тарифний план з обмеженою кількістю відкритих ' +
    'заявок, необхідно знати, що статуси поділяються на дві категорії:',
    [HelpAboutRequestKey.ACTIVE_STATUSES_TITLE]: '(Заявка рахується відкритою):',
    [HelpAboutRequestKey.NO_ACTIVE_STATUSES_TITLE]: '(Заявка рахується неактивною на данний момент або закритою):',
    [HelpAboutRequestKey.A_IN_WORK]: 'В роботі;',
    [HelpAboutRequestKey.A_WAIT_SPARE_PARTS]: 'В очікуванні запчастин;',
    [HelpAboutRequestKey.A_IN_WORK_CONTRACTOR]: 'В роботі у підрядника;',
    [HelpAboutRequestKey.A_WORKS_FINISHED]: 'Роботи завершені;',
    [HelpAboutRequestKey.A_WAIT_PAY]: 'В очікуванні оплати;',
    [HelpAboutRequestKey.A_WAIT_GIVE_CAR]: 'В очікуванні видачі автомобіля.',
    [HelpAboutRequestKey.NA_NEW]: 'Нова;',
    [HelpAboutRequestKey.NA_WAIT_AGREEMENT]: 'На узгодженні;',
    [HelpAboutRequestKey.NA_AGREEMENT]: 'Узгоджено;',
    [HelpAboutRequestKey.NA_WAIT_RECEIVE_CAR]: 'В очікуванні прийому автомобіля;',
    [HelpAboutRequestKey.NA_WAIT_WORK]: 'В очікуванні на прийняття в роботу;',
    [HelpAboutRequestKey.NA_CAR_GAVE]: 'Автомобіль виданий;',
    [HelpAboutRequestKey.NA_CLOSE]: 'Закрита;',
    [HelpAboutRequestKey.NA_CANCEL]: 'Відхилена;',
  },
  [AppTranslationKey.EMPLOYEES_PROFILES]: {
    [EmployeesProfilesKey.TITLE]: 'Профілі cпівробітників',
    [EmployeesProfilesKey.ADD_BUTTON_TITLE]: 'Додати співробітника',
    [EmployeesProfilesKey.NO_UNIT_TITLE]: 'У Вас немає жодного співробітника :(',
    [EmployeesProfilesKey.NO_UNIT_TEXT]: 'Натисніть кнопку "Додати співробітника" щоб створити його',
    [EmployeesProfilesKey.MODAL_TITLE]: 'Співробітник',
    [EmployeesProfilesKey.FIELDS]: {
      [EmployeesProfilesKey.FIRST_NAME]: 'Ім\'я',
      [EmployeesProfilesKey.SECOND_NAME]: 'Прізвище',
      [EmployeesProfilesKey.PATRONYMIC_NAME]: 'По батькові',
      [EmployeesProfilesKey.POSITION]: 'Посада',
      [EmployeesProfilesKey.REGISTRATION_DATE]: 'Дата прийняття на роботу',
      [EmployeesProfilesKey.STATUS]: 'Статус',
      [EmployeesProfilesKey.DISMISSAL_DATE]: 'Дата звільнення',
      [EmployeesProfilesKey.METHOD_OF_SALARY]: 'Спосіб нарахування заробітної плати',
      [EmployeesProfilesKey.BIRTHDATE]: 'День народження',
      [EmployeesProfilesKey.PHONE]: 'Телефон',
      [EmployeesProfilesKey.VIBER]: 'Viber',
      [EmployeesProfilesKey.TELEGRAM]: 'Telegram',
      [EmployeesProfilesKey.EMAIL]: 'Email',
      [EmployeesProfilesKey.ADD_INFO]: 'Додаткова інформація',
      [EmployeesProfilesKey.PERCENT_WORKS]: 'Розмір відсотка від вартості виконаних робіт',
      [EmployeesProfilesKey.PERCENT_SPARES]: 'Враховувати відсоток від продажу запчастин',
      [EmployeesProfilesKey.HOURLY_RATE]: 'Розмір погодинної ставки',
      [EmployeesProfilesKey.FIXED_RATE]: 'Розмір фіксованої щомісячної ставки',
      [EmployeesProfilesKey.INVALID_PHONE_NUMBER]: 'Недійсний номер телефону',
      [EmployeesProfilesKey.EMAIL_ERROR_TEXT]: 'Вкажіть дійсну електронну пошту',
    },
    [EmployeesProfilesKey.REQUIRED_FORM_FIELD]: 'Це обов`язкове поле',
  },
  [AppTranslationKey.SELECT_COMPONENT]: {
    [SelectComponentKey.SEARCH_SELECT_PLACEHOLDER]: 'Почніть писати для вибору ...',
    [SelectComponentKey.OTHER_OPTION_NAME]: 'Інше...',
  },
};
