import {
  AppTranslationKey,
  CarWorkTranslationKey,
  DataStatus,
  Pages,
} from 'common/enums/enums';
import { CarWorkGetAllResponse, WithCarServicesTabs } from 'common/types/types';
import { usePermissionToastContext } from 'components/common/common';
import { CarWorkModal } from 'components/modals/modals';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useAppDispatch, useAppSelector, useCallback, useEffect, useState } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { carWorkActions } from 'store/actions';
import { updateCarWork } from 'store/car-work/reducer';

import { FavCarWorkTable } from './components/components';

const FavCarWork: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [ isCarWorkModalOpen, setIsCarWorkModalOpen ] = useState<boolean>(false);
  const {
    favoritesCarWork,
    allCarWork,
    getAllCarWorkDataStatus,
    favoritesCarWorkDataStatus,
  } = useAppSelector((state) => state.carWork);
  const { onPermissionAction } = usePermissionToastContext();

  const isLoadingFavCarWork = favoritesCarWorkDataStatus === DataStatus.PENDING;
  const isLoadingAllCarWork = getAllCarWorkDataStatus === DataStatus.PENDING;

  const handleAddFavCarWork = (): void => {
    activeCarService && dispatch(carWorkActions.getAllCarWorkByCarService({ carServiceId: activeCarService.id }));
    setIsCarWorkModalOpen(true);
  };

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_WORK}:${CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON}`,
      action: onPermissionAction(handleAddFavCarWork),
    });

    return () => {
      removeExtraButton();
    };
  }, [ favoritesCarWork, i18n.language ]);

  useEffect(() => {
    activeCarService && dispatch(carWorkActions.getFavoritesCarWork({ carServiceId: activeCarService?.id ?? 0 }));
  }, [ activeCarService, dispatch ]);

  const handleCloseCarWorkModal = (): void =>  {
    setIsCarWorkModalOpen(false);
  };

  const handleSelectCarWork = useCallback((item: CarWorkGetAllResponse): void => {
    dispatch(updateCarWork(item.serviceWorkId));
  }, [dispatch]);

  const handleSubmit = useCallback((): void => {
    const workIds =
      allCarWork.filter( (item) => item.isFavorite).map((item) => item.serviceWorkId);
    dispatch(carWorkActions.updFavListByCarServiceId({
      carServiceId: activeCarService?.id ?? 0,
      workIds: workIds,
    })).unwrap().then(() => handleCloseCarWorkModal());
  }, [activeCarService, allCarWork, dispatch]);

  return (
    <>
      <FavCarWorkTable
        isLoading={isLoadingFavCarWork}
        favoritesCarWork={favoritesCarWork}
        onAddFavCarWork={handleAddFavCarWork}
      />
      <CarWorkModal
        onSubmit={handleSubmit}
        isLoading={isLoadingAllCarWork}
        tableData={allCarWork}
        onSelectCarWork={handleSelectCarWork}
        isOpen={isCarWorkModalOpen}
        onCloseModal={handleCloseCarWorkModal}
      />
    </>
  );
};
export const CarWorkWithServiceTabs =
  withCarServicesTabs(
    FavCarWork,
    false,
    Pages.WORKS,
    () => t('mainMenu:work') ?? '',
  );
