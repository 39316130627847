import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { useAppSelector, useEffect, useFieldArray } from 'hooks/hooks';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from '../../styles.module.scss';

enum TypeSalary {
  PERCENT_WORKS= 'PERCENT_WORKS',
  PERCENT_SPARES= 'PERCENT_SPARES',
  HOURLY_RATE= 'HOURLY_RATE',
  FIXED_RATE= 'FIXED_RATE',
}

const salaryConfig: Record<TypeSalary, { label: string, isPercent: boolean, step: number }> = {
  'PERCENT_WORKS': {
    label: EmployeesProfilesKey.PERCENT_WORKS,
    isPercent: true,
    step: 0.01,
  },
  'PERCENT_SPARES': {
    label: EmployeesProfilesKey.PERCENT_SPARES,
    isPercent: true,
    step: 0.01,
  },
  'HOURLY_RATE': {
    label: EmployeesProfilesKey.HOURLY_RATE,
    isPercent: false,
    step: 1,
  },
  'FIXED_RATE': {
    label: EmployeesProfilesKey.FIXED_RATE,
    isPercent: false,
    step: 1,
  },
};

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  control: Control<EmployeeFormData>,
};

const SalaryField: FC<Props> = ({ register, control }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const salaryTypes = useAppSelector(({ dictionary }) => dictionary.data?.salaryTypes || []);
  const { fields, append } = useFieldArray({
    control,
    name: 'salaryTypes',
    rules: { required: true },
  });

  useEffect(() => {
    if (fields.length === 0) {
      const salaries = salaryTypes.map((salary) => ({
        salaryTypeId: salary.salaryTypeId,
        salaryTypeDesc: salary.salaryTypeDescription,
        salaryTypeName: salary.salaryTypeName,
        salaryTypeValue: 0,
      }));

      append(salaries);
    }
  }, [salaryTypes]);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="position" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.METHOD_OF_SALARY }`) }*` }
      </CFormLabel>
      <CCol sm={9}>
        {
          fields.map((field, index) => (
            <div key={field.id} className={ styles.salaryFieldItem }>
              <div className={ styles.itemDescription }>
                { t(`${EmployeesProfilesKey.FIELDS}.${salaryConfig[field.salaryTypeName as TypeSalary].label}`) }
              </div>
              <CFormInput
                {...register(`salaryTypes.${index}.salaryTypeValue`)}
                className={ styles.itemInput }
                type="number"
                min={ 0 }
                step={ salaryConfig[field.salaryTypeName as TypeSalary].step }
              />
              <span>{ salaryConfig[field.salaryTypeName as TypeSalary].isPercent ? '%' : tCommon('uah') }</span>
            </div>
          ))
        }
      </CCol>
    </CRow>
  );
};

export { SalaryField };
