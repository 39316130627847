import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { BodyType, CarModalFormData } from 'common/types/types';
import { SelectWithLocalSearch } from 'components/common/select';
import React, { FC, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectComponentKey } from '../../../../common/enums/translation-key/select-component-key.enum';

type Props = {
  control: Control<CarModalFormData>,
  bodyTypes: BodyType[],
  disabled?: boolean,
};

const BodyTypeField: FC<Props> = ({
  disabled = false, control, bodyTypes,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const { t: selectT } = useTranslation(AppTranslationKey.SELECT_COMPONENT);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="bodyType" className="col-sm-3 col-form-label">
        {t('bodyType')}
      </CFormLabel>
      <CCol sm={9}>
        <Controller
          name="bodyType"
          render={({ field }): ReactElement => (
            <SelectWithLocalSearch<BodyType>
              id="bodyType"
              data={ bodyTypes }
              value={ field.value }
              onChange={ (value): void => field.onChange(value) }
              getOptionLabel={ (option): string => option.name }
              getOptionValue={ (option): string => option.id + '' }
              otherOption={ { id: -1, name: selectT(SelectComponentKey.OTHER_OPTION_NAME) } }
              isDisabled={ disabled }
            />
          )}
          control={control}
        />
      </CCol>
    </CRow>
  );
};

export { BodyTypeField };
