import { CButton, CModalFooter } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

type Props = {
  onClose: () => void,
};

const ModalFooter: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation(AppTranslationKey.COMMON);

  return (
    <CModalFooter className={styles.modalFooter}>
      <CButton color="dark" variant="ghost" onClick={onClose}>
        {t('closeBtn')}
      </CButton>
      <CButton color="primary" type="submit">
        {t('saveBtn')}
      </CButton>
    </CModalFooter>
  );
};

export { ModalFooter };
