import { CarModalFormData } from 'common/types/types';

const DEFAULT_CAR_MODAL_PAYLOAD: CarModalFormData = {
  vinCode: '',
  brand: null,
  model: null,
  bodyType: null,
  carColorId: null,
  carRegNum: '',
  createYear: null,
  mileageKm: null,
  capacity: null,
  carInfo: '',
};

export { DEFAULT_CAR_MODAL_PAYLOAD };
