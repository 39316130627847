import { CCol, CFormFeedback, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { phone as checkPhone } from 'phone';
import React, { FC, ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import styles from '../../styles.module.scss';

type Props = {
  control: Control<EmployeeFormData>,
};

const PhoneField: FC<Props> = ({ control }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const invalidPhoneMessage = t(`${EmployeesProfilesKey.FIELDS}.${EmployeesProfilesKey.INVALID_PHONE_NUMBER}`);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="phone" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.PHONE }`) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <Controller
          render={({ field, formState: { errors } }): ReactElement => (
            <div className={ styles.containerPhoneField }>
              <PhoneInput
                inputProps={ { id: 'phone', name: field.name } }
                value={ field.value }
                onChange={(value): void => field.onChange(value)}
                country={'ua'}
                placeholder=""
                countryCodeEditable={false}
                enableSearch
                disableSearchIcon
                containerClass={ cn( { 'is-invalid': Boolean(errors.phone) }) }
                inputClass={ styles.phoneFieldInput }
              />
              <CFormFeedback invalid={ Boolean(errors.phone) }>
                {errors.phone?.message || ''}
              </CFormFeedback>
            </div>
          )}
          rules={ { validate: (value) => checkPhone(`+${ value }`).isValid || invalidPhoneMessage } }
          name="phone"
          control={ control }
        />
      </CCol>
    </CRow>
  );
};

export { PhoneField };
