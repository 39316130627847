import { AppTranslationKey, EmployeesProfilesKey, Pages } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { NoDataWithAction, usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts';
import { withCarServicesTabs } from 'hoc/hoc';
import { useEffect, useEmployeeModal } from 'hooks/hooks';
import { t as tt } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const EmployeesProfiles: FC<WithCarServicesTabs> = ({ activeCarService }) => {
  const { id } = activeCarService || {};
  const { t, i18n } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();
  const { getModal, toggleModal } = useEmployeeModal({ carServiceId: id || -1 });

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.ADD_BUTTON_TITLE}`,
      action: onPermissionAction((): void => toggleModal(true), Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, [i18n.language]);

  return (
    <>
      <NoDataWithAction
        title={t(EmployeesProfilesKey.NO_UNIT_TITLE)}
        message={t(EmployeesProfilesKey.NO_UNIT_TEXT)}
        actionName={t(EmployeesProfilesKey.ADD_BUTTON_TITLE)}
        callback={ onPermissionAction((): void => toggleModal(true), Tariffs.NO_TARIFF) }
      />
      { getModal() }
    </>
  );
};

export const StorageProfilesWithCarServicesTabs = withCarServicesTabs(
  EmployeesProfiles,
  false,
  Pages.EMPLOYEES,
  () => tt('mainMenu:employeesProfiles') ?? '',
);
