import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  errors: FieldErrors<EmployeeFormData>,
};

const EmailField: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const errorEmailMessage = t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.EMAIL_ERROR_TEXT }`);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="email" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.EMAIL }`) }` }
      </CFormLabel>
      <CCol sm={ 9 }>
        <CFormInput
          { ...register('email',
            {
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: errorEmailMessage ?? 'Please enter a valid email address',
              },
            },
          ) }
          name="email"
          type="text"
          placeholder={t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.EMAIL }`) as string}
          invalid={Boolean(errors.email)}
          feedbackInvalid={errors.email?.message || ''}
        />
      </CCol>
    </CRow>
  );
};

export { EmailField };
