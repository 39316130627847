import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, Employee, EmployeeDto } from 'common/types/types';

import { ActionType } from './common';

const getEmployees = createAsyncThunk<
  Employee[],
  { carServiceId: number },
  AsyncThunkConfig
>(ActionType.GET_EMPLOYEES, async (payload, { extra }) => {
  const { employeesApi } = extra;

  return employeesApi.getEmployeesByCarServiceId(payload);
});

const createEmployee = createAsyncThunk<
  EmployeeDto,
  EmployeeDto,
  AsyncThunkConfig
>(ActionType.CREATE_EMPLOYEE, async (payload, { extra }) => {
  const { employeesApi } = extra;

  return employeesApi.createEmployee(payload);
});

export { createEmployee, getEmployees };
